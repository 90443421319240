import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TaxPageTemplate from '../components/TaxPageTemplate'
import { HTMLContent } from '../components/Content'

const TaxPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <TaxPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        notice={post.frontmatter.notice}
        rateSchedule={post.frontmatter.taxRateSchedule}
      />
    </Layout>
  )
}

TaxPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TaxPage

export const taxPageQuery = graphql`
  query TaxPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        notice {
          title
          message
        }
        taxRateSchedule {
          year
          ds
          mo
          order {
            publicURL
          }
        }
      }
    }
  }
`
